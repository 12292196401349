import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Nav from './Nav';

import '../css/typography.css';

export default class Template extends React.Component {
  static propTypes = {
    children: PropTypes.func,
  };

  render() {
    const { location } = this.props;

    const isRoot = location.pathname === '/';

    return (
      <React.Fragment>
        <Helmet
          title="John Vorwald"
          meta={[
            { name: 'description', content: 'John Vorwald' },
            { name: 'keywords', content: 'Web Development' },
          ]}
        >
          <link href="https://fonts.googleapis.com/css?family=Playfair+Display:900,400|Noto+Serif+SC:300,700" rel="stylesheet" />
          <html lang="en" />
        </Helmet>
        <Nav></Nav>
        <div className="header">
          <div>
            <h1>
              <Link to="/">
                John Vorwald
              </Link>
            </h1>
          </div>
        </div>
        <div>
          {this.props.children}
        </div>
      </React.Fragment>
    );
  }
}
